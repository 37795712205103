<template>
    <div class="scan">

        <ScannerCamera />
        <v-btn fab color="primary" small class="qrcode-button" @click="showSearchDialog = true">
            <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <div class="target-camera" @click="simulateScan()">
            <v-img max-width="300" max-height="300" src="@/assets/img/cam-overlay.png" />
            <div class="scanner-explanation" :style="{backgroundColor: $vuetify.theme.themes.light.primary+ '30'}">
                <p v-if="!error" :style="{backgroundColor: $vuetify.theme.themes.light.primary + '30'}" class="ma-0 px-3 py-5 white--text font-weight-bold text-center font-weight-medium">
                    {{$t("customerScanExplain")}}
                </p>
                <p v-else :style="{backgroundColor: $vuetify.theme.themes.light.error + '80'}" class="ma-0 px-3 py-5 white--text font-weight-bold text-center font-weight-medium">
                    {{$t("customerScanError")}}
                </p>
            </div>
        </div>

        <audio id="beep" ref="beep" controls hidden>
            <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
            {{$t('errorSound')}}
        </audio>

        <v-dialog v-model="showSearchDialog" max-width="600px" content-class="rounded-xl">
            <v-card>
                <v-card-title>
                    <span class="primary--text text-h5 text-center">{{$t("customerSearchModalTitle")}}</span>
                </v-card-title>
                <v-card-text>
                    <v-container class="px-0">
                        <v-list v-if="showSearchList" subheader>
                            <v-subheader class="px-0">{{$t("customerSearchModalResults")}}</v-subheader>
                            <v-list-item v-for="(result, id) in results" :key="`result-${id}`" class="px-0" @click="handleResultSelected()">
                                <v-list-item-content>
                                    <v-list-item-title> {{result.firstName}} {{result.lastName}} </v-list-item-title>
                                    <v-list-item-title class="text-caption">
                                        <v-icon small>mdi-map-marker</v-icon> {{result.zipCode}} | <v-icon small>mdi-at
                                        </v-icon> {{result.email}} | <v-icon small>mdi-phone</v-icon>
                                        {{result.phoneNumber}}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn icon>
                                        <v-icon color="grey lighten-1">mdi-information</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                        <template v-else>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="firstNameInput"
                                        :label="$t('customerSearchModalFirstNamePlaceholder')" hide-details>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="lastNameInput"
                                        :label="$t('customerSearchModalLastNamePlaceholder')" hide-details>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="emailInput"
                                        :label="$t('customerSearchModalEmailPlaceholder')" hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="phoneInput"
                                        :label="$t('customerSearchModalPhonePlaceholder')" hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </template>
                    </v-container>
                </v-card-text>


                <v-card-actions class="justify-center">
                    <v-btn v-if="showSearchList" color="primary" class="mb-2 modal-action"
                        @click="showSearchList = false">
                        <v-icon>mdi-chevron-left</v-icon> {{$t("customerSearchModalBackBtnLabel")}}
                    </v-btn>
                    <v-btn v-else color="primary" class="mb-2 modal-action" @click="handleSearchBtnClick()"
                        :loading="searchLoading">
                        <v-icon>mdi-magnify</v-icon> {{$t("customerSearchModalBtnLabel")}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showCustomerInfoDialog" content-class="rounded-0" fullscreen>

            <v-card class="rounded-0 pa-4 customer-info-dialog">
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="showCustomerInfoDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{$t("customerInfosTitle")}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="pa-3">
                    <div class="d-flex flex-column align-center">
                        <v-avatar color="grey" size="64">
                            <v-icon color="white" large>mdi-account</v-icon>
                        </v-avatar>
                        <span class="my-3 primary--text text-h5">Jean Dupond</span>
                        <p class="ma-0 text-body font-weight-medium black--text">
                            <v-icon small>mdi-at</v-icon> jean.dupond@worldline.com
                        </p>
                        <p class="ma-0 text-body font-weight-medium black--text">
                            <v-icon small>mdi-phone</v-icon> 06 12 34 56 78
                        </p>
                        <p class="ma-0 text-body font-weight-medium black--text">
                            <v-icon small>mdi-map-marker</v-icon> 22 Rue Jean-Nicot, 75007 Paris
                        </p>
                        <p class="ma-0 text-body font-weight-medium black--text">
                            <v-icon small>mdi-cake-variant</v-icon> 27/09/1990
                        </p>

                        <p class="ma-0 mt-3 text-body font-weight-bold black--text">{{$t("customerScanInfoModalClientSinceLabel")}}: 10/09/2016 </p>
                        <p class="ma-0 mt-3 text-body font-weight-bold black--text">{{$t("customerScanInfoModalLastVisitLabel")}}: 27/09/2022 </p>
                        <!-- <p class="ma-0 mt-3 text-body font-weight-bold black--text">{{$t("customerScanInfoModalLoyaltyBalance", 45, {balance: 45})}} </p> -->
                        <!-- <p class="ma-0 mt-3 text-body font-weight-bold black--text">Purchase history : 45 points </p> -->
                    </div>
                </v-card-text>
                <v-divider class="primary mx-8 my-4"></v-divider>
                <v-row class="my-3" justify="space-around">
                    <div class="d-flex flex-column align-center">
                        <v-avatar color="primary" size="64">
                            <span class="white--text font-weight-bold text-body-2">255.00€</span>
                        </v-avatar>
                        <span class="text-caption">{{$t("customerScanInfoModalGlobalAmountLabel")}}</span>
                    </div>

                    <div class="d-flex flex-column align-center">
                        <v-avatar color="primary" size="64">
                            <span class="white--text text-h5">3</span>
                        </v-avatar>
                        <span class="text-caption">{{$t("customerScanInfoModalNbOfVisitsLabel")}}</span>
                    </div>

                    <div class="d-flex flex-column align-center">
                        <v-avatar color="primary" size="64">
                            <span class="white--text text-h5">45</span>
                        </v-avatar>
                        <span class="text-caption">{{$t("customerScanInfoModalLoyaltyLabel")}}</span>
                    </div>
                </v-row>
                <v-divider class="primary mx-8 my-4"></v-divider>

                <p class="mx-4 font-weight-bold text-body-1">{{$t("customerScanInfoModalOrderHistoryLabel")}}</p>
                <div v-if="this.$vuetify.breakpoint.xsOnly" class="mb-4"> 
                    <v-expansion-panels flat>
                        <v-expansion-panel v-for="order in orderHistory" :key="`order-${order.id}`">
                            <v-expansion-panel-header>
                                <p class="ma-0 text-body-2"><span class="font-weight-medium">{{order.store}}</span> - <span class="text-caption font-italic">{{order.date}}</span> - <span class="text-caption font-italic">{{formatPrice(order.amount)}}</span></p>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                 <div class="my-2 full-width d-flex align-center">
                                    <span class="text-body-2 font-weight-medium">{{$t("customerScanInfoModalStoreLabel")}}: </span>
                                    <span class="mx-2 text-body-2 font-weight-medium">{{order.store}}</span>
                                    <v-spacer></v-spacer>
                                </div>   
                                <v-divider class="mx-8 my-4"></v-divider>
                                <template v-for="product in order.products" >
                                    <v-row :key="`product-${product.id}-${order.id}`">
                                        <v-col cols="2" >
                                            <span class="text-body-2">{{product.qty}}x</span>
                                        </v-col>
                                        <v-col cols="6" >
                                            <span class="text-body-2">{{product.name}}</span>
                                        </v-col>
                                        <v-col cols="3" >
                                            <span class="text-body-2">{{formatPrice(product.price)}}</span>
                                        </v-col>
                                    </v-row>
                                </template>                                    
                                <v-divider class="mx-8 my-4"></v-divider>

                                <div class="full-width d-flex align-center">
                                    <v-spacer></v-spacer>
                                    <span class="text-body-1 font-weight-medium">{{$t("customerScanInfoModalTotalAmountLabel")}}</span>
                                    <v-spacer></v-spacer>
                                    <span>{{formatPrice(order.amount)}}</span>
                                </div>
                            </v-expansion-panel-content>
                            <v-divider class="mx-5"></v-divider>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                <template v-else>
                    <v-data-table :headers="orderHistoryHeaders" :items="orderHistory" hide-default-header disable-sort single-expand item-key="id" :expanded.sync="expanded" hide-default-footer show-expand class="elevation-0">
                        <template v-slot:header="{ props }">
                            <th class="text-caption grey--text text--darken-1 font-weight-bold" :class="`text-${head.align}`" v-for="head in props.headers" :key="`header-1-${head.text}`"><span class="mx-2">{{ getTranslate(head.text) }}</span></th>
                        </template>
                        <template v-slot:item.amount="{ item }">
                            {{formatPrice(item.amount)}}
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="elevation-0 pa-3">
                                <template v-for="product in item.products" >
                                    <v-row :key="`product-${product.id}`">
                                        <v-col cols="1" >
                                            <span>{{product.qty}}x</span>
                                        </v-col>
                                        <v-col cols="8" >
                                            <span>{{product.name}}</span>
                                        </v-col>
                                        <v-col cols="2" >
                                            <span>{{formatPrice(product.price)}}</span>
                                        </v-col>
                                    </v-row>
                                    <v-divider :key="`divider-${product.id}`" class="mx-8 my-4"></v-divider>
                                </template>
                            </td>
                        </template>
                    </v-data-table>
                    <v-divider class="mb-4"></v-divider>
                    <v-divider class="mx-8 my-4"></v-divider>
                </template>

                

                <p class="mx-4 font-weight-bold text-body-1">{{$t("customerScanInfoModalContactHistoryLabel")}}</p>
                <template v-if="this.$vuetify.breakpoint.xsOnly">
                    <v-list>
                        <template v-for="(contactElmt, i) in contactHistory">
                            <v-list-item :key="`contact-${i}`">
                                <v-list-item-icon>
                                    <v-icon v-text="contactElmt.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-body-2">{{$t(contactElmt.reason)}}</v-list-item-title>
                                    <v-list-item-subtitle class="text-caption">
                                        <span class="font-italic">{{contactElmt.date}}</span>
                                         - 
                                        <span class="font-italic">{{contactElmt.store}}</span>  
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mx-5" v-if="i < contactHistory.length - 1" :key="`contact-div-${i}`"></v-divider>
                        </template>
                    </v-list>
                </template>
                <template v-else>
                    <v-data-table :headers="contactHistoryHeaders" :items="contactHistory" item-key="date" hide-default-header disable-sort hide-default-footer class="elevation-0">
                        <template v-slot:header="{ props }">
                            <th class="text-caption grey--text text--darken-1 font-weight-bold" :class="`text-${head.align}`" v-for="head in props.headers" :key="`header-1-${head.text}`"><span class="mx-2">{{ getTranslate(head.text) }}</span></th>
                        </template>
                        <template v-slot:item.type="{ item }">
                            {{$t(item.type)}}
                        </template>
                        <template v-slot:item.reason="{ item }">
                            {{$t(item.reason)}}
                        </template>
                    </v-data-table>
                </template>
            </v-card>
        </v-dialog>

    </div>

</template>

<script>
    import ScannerCamera from "@/components/subcomponents/ScannerCamera.vue";
    let Barcode = require("@/assets/js/Barcode");
    import {
        mapActions,
        mapState
    } from 'vuex'

    export default {
        name: 'ScanCustomer',
        components: {
            ScannerCamera,
        },
        data() {
            return {
                cameras: [],
                cameraToUse: null,
                error: false,
                showSearchDialog: false,
                showCustomerInfoDialog: false,
                showSearchList: false,
                searchLoading: false,
                firstNameInput: "Jean",
                lastNameInput: "Dupond",
                emailInput: "",
                phoneInput: "",
                results: [{
                        id: 1,
                        firstName: "Jean",
                        lastName: "Dupond",
                        email: "jean.dupond@worldline.com",
                        zipCode: "59000",
                        phoneNumber: "06 11 11 11 22"
                    },
                    {
                        id: 1,
                        firstName: "Jean",
                        lastName: "Dupond",
                        email: "jean.dupond1@worldline.com",
                        zipCode: "75000",
                        phoneNumber: "06 33 11 11 22"
                    }
                ],
                expanded: [],
                orderHistoryHeaders: [{
                        text: 'customerScanInfoModalTableDateLabel',
                        align: 'start',
                        sortable: false,
                        value: 'date',
                    },
                    {
                        text: 'customerScanInfoModalTableStoreLabel',
                        align: 'start',
                        value: 'store'
                    },
                    {
                        text: 'Nb Products',
                        align: 'center',
                        value: 'nbProducts'
                    },
                    {
                        text: 'customerScanInfoModalTableAmountLabel',
                        align: 'center',
                        value: 'amount'
                    },
                    {
                        text: 'customerScanInfoModalTablePromoLabel',
                        value: 'promo',
                        align: 'center',
                    },
                    {
                        text: '',
                        value: 'data-table-expand'
                    },
                ],
                orderHistory: [{
                        id: 1,
                        date: '10/08/2022',
                        store: "Damart Paris, Chatelet",
                        nbProducts: 3,
                        amount: 75,
                        promo: "-",
                        products: [
                            {
                                id: 1,
                                name: "T-shirt",
                                price: 15,
                                qty: 2
                            },
                            {
                                id: 2,
                                name: "Pantalon",
                                price: 40,
                                qty: 1
                            }
                        ]
                    },
                    {
                        id: 2,
                        date: '01/07/2022',
                        store: "Damart Paris, Haussmann",
                        nbProducts: 4,
                        amount: 105,
                        promo: "-",
                        products: [
                            {
                                id: 1,
                                name: "T-shirt",
                                price: 15,
                                qty: 2
                            },
                            {
                                id: 2,
                                name: "Bonnet",
                                price: 15,
                                qty: 1
                            },
                            {
                                id: 3,
                                name: "Pantalon",
                                price: 60,
                                qty: 1
                            }
                        ]
                    },
                    {
                        id: 3,
                        date: '10/05/2022',
                        store: "Web",
                        nbProducts: 2,
                        amount: 50,
                        promo: "1 acheté 1 offert",
                        products: [
                            {
                                id: 1,
                                name: "Pantalon",
                                price: 50,
                                qty: 2
                            }
                        ]
                    },
                ],

                contactHistoryHeaders: [{
                        text: 'customerScanInfoModalTableDateLabel',
                        align: 'start',
                        sortable: false,
                        value: 'date',
                    },
                    {
                        text: 'customerScanInfoModalTableTypeLabel',
                        align: 'start',
                        value: 'type'
                    },
                    {
                        text: 'customerScanInfoModalTableReasonLabel',
                        align: 'start',
                        value: 'reason'
                    },
                    {
                        text: 'customerScanInfoModalTableDurationLabel',
                        align: 'center',
                        value: 'duration'
                    },
                    {
                        text: 'customerScanInfoModalTableStoreLabel',
                        align: 'start',
                        value: 'store'
                    }
                ],
                contactHistory: [{
                        id: 1,
                        date: '27/09/2022',
                        store: "Damart Paris, Chatelet",
                        type: "customerScanInfoModalTypeSmsLabel",
                        reason: "customerScanInfoModalBirthdayLabel",
                        duration: "-",
                        icon: "mdi-message"
                    },
                    {
                        id: 2,
                        date: '10/08/2021',
                        store: "Damart Paris, Chatelet",
                        type: "customerScanInfoModalTypePhoneLabel",
                        reason: "customerScanInfoModalFollowUpLabel",
                        duration: "15 min",
                        icon: "mdi-phone"
                    },
                    {
                        id: 3,
                        date: '27/09/2020',
                        store: "Damart Paris, Chatelet",
                        type: "customerScanInfoModalTypeEmailLabel",
                        reason: "customerScanInfoModalBirthdayLabel",
                        duration: "-",
                        icon: "mdi-at"
                    },
                    {
                        id: 4,
                        date: '15/08/2019',
                        store: "Damart Paris, Chatelet",
                        type: "customerScanInfoModalTypeEmailLabel",
                        reason: "customerScanInfoModalFollowUpLabel",
                        duration: "-",
                        icon: "mdi-at"
                    },
                    {
                        id: 3,
                        date: '27/09/2018',
                        store: "Damart Paris, Chatelet",
                        type: "customerScanInfoModalTypeEmailLabel",
                        reason: "customerScanInfoModalBirthdayLabel",
                        duration: "-",
                        icon: "mdi-at"
                    }
                ],
            }
        },

        created() {
            this.changeCurrentPage(this.$route.meta.page)
        },
        mounted() {
            Barcode.start(this.onDecode);
        },
        methods: {
            ...mapActions('header', ['changeCurrentPage']),

            simulateScan() {
                let code = {
                    codeResult: {
                        code: "4000158349860"
                    }
                }
                this.onDecode(code)
            },
            onDecode(decodedString) {
                this.$refs.beep.play();
                let ean = decodedString.codeResult.code
                if (ean.indexOf("4000") == 0) {
                    this.showLoader({
                        message: this.$t("customerScanloading"),
                        color: ""
                    })
                    setTimeout(() => {
                        this.hideLoader()
                        this.showCustomerInfoDialog = true
                    }, 2000);
                } else {
                    this.error = true
                }
            },
            handleResultSelected() {
                this.showSearchDialog = false
                this.showLoader({
                    message: this.$t("customerScanloading"),
                    color: ""
                })
                setTimeout(() => {
                    this.hideLoader()
                    this.showCustomerInfoDialog = true
                    this.showSearchList = false
                }, 2000);
            },
            handleSearchBtnClick() {
                this.searchLoading = true
                setTimeout(() => {
                    this.showSearchList = true
                    this.searchLoading = false
                }, 2000);
            },
            getTranslate(key){
                console.log(this.$t(key));
                return this.$t(key)
            }

        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            Barcode.reset()
            next();
        },
        watch: {
            cameras() {
                Barcode.reset()
                this.cameraToUse = this.cameras[0]
            },
            cameraToUse(camera) {
                Barcode.reset()
                Barcode.start(camera.device, this.onDecode);
            }
        }

    };
</script>
<style scoped>
    .qrcode-button {
        position: absolute;
        top: 175px;
        right: 32px;
        z-index: 200;
    }

    .scan {
        height: 100%;
        height: calc(var(--vh, 1vh) * 100);
        background: black;
        position: relative;
    }

    .target-camera {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .scanner-explanation {
        position: absolute;
        width: 100%;
    }

    .modal-action {
        border-radius: 8px;
    }

    .customer-info-dialog {
        overflow: hidden;
    }
</style>